import { reportNavType } from '@edulastic/constants/const/report'
import API from '../utils/API'
import { prefix } from './utils'

const api = new API()

const { DW_DOMAIN_ANALYSIS_REPORT } = reportNavType

const getDomainAnalysisSummary = (data) => {
  return api
    .callApi({
      useSlowApi: true,
      url: `${prefix}/${DW_DOMAIN_ANALYSIS_REPORT}/summary`,
      method: 'post',
      data,
    })
    .then((result) => result.data)
}

const getDomainAnalysisDetails = (data) => {
  return api
    .callApi({
      useSlowApi: true,
      url: `${prefix}/${DW_DOMAIN_ANALYSIS_REPORT}/details`,
      method: 'post',
      data,
    })
    .then((result) => result.data)
}

const getSkillInfo = (data) => {
  return api
    .callApi({
      useSlowApi: true,
      url: `${prefix}/${DW_DOMAIN_ANALYSIS_REPORT}/skill-info`,
      method: 'post',
      data,
    })
    .then((r) => r.data)
}

export default {
  getDomainAnalysisSummary,
  getDomainAnalysisDetails,
  getSkillInfo,
}
